import { extend, configure } from 'vee-validate';
import {
    required,
    email,
    confirmed,
    min,
    max,
    numeric,
} from 'vee-validate/dist/rules';

import {
    COMPLEX_PASSWORD_RULE_TYPE,
    PHONE_NUMBER_RULE_TYPE,
    SOFT_PHONE_NUMBER_RULE_TYPE,
    IBAN_NUMBER_RULE_TYPE,
    POSTCODE_RULE_TYPE,
    NIP_RULE_TYPE,
    NIP_CONTROL_SUM_RULE_TYPE,
    REQUIRED_RULE_TYPE,
    EMAIL_RULE_TYPE,
    CONFIRMED_RULE_TYPE,
    MAX_TYPE,
    MIN_TYPE,
    NUMERIC_TYPE,
    EMAIL_CHARACTERS_TYPE,
} from '@configs/validations';
import { complexPasswordRegexRules } from '@configs/complex-password-validation';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import {
    NIP_VALIDATION_RULE_NAME,
    POSTCODE_VALIDATION_RULE_NAME,
} from '@localeConfig/keys';

import getRmaValidations from '@rma/assets/validations';
import { isValidIban, getIbanExample } from '@assets/iban';
import { getLocaleISOCode, convertIsoToLocale } from '@assets/locale';
import { APP_LINK_REGEXP } from '@assets/link';
import { isHexColor } from '@assets/color';
import { REGEXP_VIMEO } from '@assets/video';

const LOCALE_PL = 'pl_PL';
const NIP_PREFIX_LOCALE_PL = 'PL';

const complexPassword = () => {
    const validationParts = Object.keys(complexPasswordRegexRules).reduce(
        (acc, key) => {
            acc[key] = false;

            return acc;
        },
        {}
    );

    return {
        validation: {
            validate: value => {
                let conditionsCounter = 0;

                Object.entries(complexPasswordRegexRules).forEach(
                    ([group, regex]) => {
                        let isValid;

                        if (Array.isArray(regex)) {
                            isValid = regex.every(rule => !!value.match(rule));
                        } else {
                            isValid = !!value.match(regex);
                        }

                        validationParts[group] = isValid;

                        if (!isValid) {
                            return;
                        }

                        conditionsCounter += 1;
                    }
                );

                return (
                    conditionsCounter ===
                    Object.keys(complexPasswordRegexRules).length
                );
            },
            message: () => validationParts,
        },
        name: COMPLEX_PASSWORD_RULE_TYPE,
    };
};

const getFailedPhoneValidationMessage = (i18n, errorCode, exampleNumber) => {
    const errorMsg = errorCode
        ? `__validations.${errorCode}`
        : '__validations.phoneNumber';

    if (!exampleNumber) {
        return `${i18n.t(errorMsg)}`;
    }

    const exampleNumberMessage = i18n.t('__validations.Example phone number', {
        examplePhoneNumber: exampleNumber.replace(/\s|\//g, ''),
    });

    return `${i18n.t(errorMsg)} ${exampleNumberMessage}.`;
};

const getPhoneNumberValidationRule = (
    $services,
    $errorHandler,
    i18n,
    name,
    {
        pattern: additionalValidationPattern = '',
        errorCode: additionalValidationErrorCode = '',
    }
) => {
    let message = '';

    return {
        validation: {
            validate: async value => {
                try {
                    const {
                        valid = null,
                        error_code: errorCode = null,
                        example_number: exampleNumber = null,
                    } = (await $services.validate.validatePhone(value)) || {};

                    if (valid === null && exampleNumber === null) {
                        message = i18n.t(
                            '__validations.Phone number validation error'
                        );

                        return false;
                    }

                    if (!valid || !additionalValidationPattern?.test(value)) {
                        const error = !valid
                            ? errorCode
                            : additionalValidationErrorCode;

                        message = getFailedPhoneValidationMessage(
                            i18n,
                            error,
                            exampleNumber
                        );

                        return false;
                    }

                    message = '';

                    return true;
                } catch (err) {
                    $errorHandler.captureError(err, {
                        [ERROR_ACTION_TAG_NAME]: 'validate.validatePhone',
                    });

                    message = i18n.t(
                        '__validations.Phone number validation error'
                    );

                    return false;
                }
            },
            message: () => message,
        },
        name,
    };
};

const getPhoneNumberValidationRules = ($services, $errorHandler, i18n) => {
    const softAdditionalValidationConfig = {
        pattern: /^(\+)?[0-9 ]+$/,
        errorCode: 'NOT_A_NUMBER',
    };

    const hardAdditionalValidationConfig = {
        pattern: /^(\+)?[0-9]+\d$/,
        errorCode: 'NOT_A_NUMBER',
    };

    return [
        getPhoneNumberValidationRule(
            $services,
            $errorHandler,
            i18n,
            SOFT_PHONE_NUMBER_RULE_TYPE,
            softAdditionalValidationConfig
        ),
        getPhoneNumberValidationRule(
            $services,
            $errorHandler,
            i18n,
            PHONE_NUMBER_RULE_TYPE,
            hardAdditionalValidationConfig
        ),
    ];
};

const ibanValidationRule = i18n => {
    let iban = '';

    return {
        validation: {
            validate: value => {
                iban = value;

                return isValidIban(value);
            },
            message: () => {
                const ibanExample = getIbanExample(iban);

                if (!ibanExample) {
                    return i18n.t('__validations.isNotValidIban');
                }

                return `${i18n.t('__validations.isNotValidIban')}. ${i18n.t(
                    '__validations.exampleOfIban'
                )} ${ibanExample}.`;
            },
        },
        name: IBAN_NUMBER_RULE_TYPE,
    };
};

const postcodeValidationRule = postcodeValidationConfig => ({
    validation: {
        validate: value =>
            postcodeValidationConfig
                ? !!value.match(postcodeValidationConfig.pattern)
                : true,
        params: [
            {
                name: 'format',
                default: postcodeValidationConfig?.format || '',
            },
        ],
    },
    name: POSTCODE_RULE_TYPE,
});

const nipValidationRule = nipValidationConfig => ({
    validation: {
        validate: value =>
            nipValidationConfig
                ? !!value.match(nipValidationConfig.pattern)
                : false,
        params: [
            {
                name: 'country',
                default: nipValidationConfig?.country || '',
            },
            {
                name: 'length',
                default: nipValidationConfig?.length || 0,
            },
        ],
    },
    name: NIP_RULE_TYPE,
});

const nipControlSumCheck = NIP => {
    const NIPString = String(NIP);
    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    const lastDigit = parseInt(NIPString.substr(-1), 10);
    const controlDigit =
        weights.reduce((total, weight, index) => {
            return total + weight * parseInt(NIPString[index], 10);
        }, 0) % 11;

    return lastDigit === controlDigit;
};

const nipControlSumValidationRule = localeISOCode => ({
    validation: {
        validate: value => {
            if (convertIsoToLocale(localeISOCode) !== LOCALE_PL) {
                return true;
            }

            return nipControlSumCheck(value.replace(NIP_PREFIX_LOCALE_PL, ''));
        },
    },
    name: NIP_CONTROL_SUM_RULE_TYPE,
});

const blikPaymentCodeRule = () => {
    return {
        validation: {
            validate: value => value.match(/^[0-9]{6}$/),
        },
        name: 'blikCode',
    };
};

const giftCardPaymentCodeRule = () => {
    return {
        validation: {
            validate: value => value.match(/^[0-9]{13}$/),
        },
        name: 'giftCardCode',
    };
};

const giftCardPaymentPinRule = () => {
    return {
        validation: {
            validate: value => value.match(/^[0-9]{4}$/),
        },
        name: 'giftCardPin',
    };
};

const emailCharactersRule = () => {
    return {
        validation: {
            validate: value => value.match(/^[\x20-\x7E]*$/),
        },
        name: EMAIL_CHARACTERS_TYPE,
    };
};

const colorHexValidationRule = i18n => {
    return {
        validation: {
            validate: value => isHexColor(value),
            message: () => {
                return i18n.t('__validations.Please provide a valid HEX color');
            },
        },
        name: 'colorHex',
    };
};

const imageRelativeUrlRule = i18n => {
    const IMAGE_FORMAT_RE = /(\w|-)+\.(jpg|jpeg|png)$/;
    const SPACES_RE = /\s/;
    const HTTP_RE = /^https?:\/\//;
    const DOT_RE = /\./g;

    return {
        validation: {
            validate: value =>
                !HTTP_RE.test(value) &&
                IMAGE_FORMAT_RE.test(value) &&
                !SPACES_RE.test(value) &&
                value.match(DOT_RE).length <= 1,
            message: () => {
                return i18n.t(
                    '__validations.Please provide a valid imageUrl address'
                );
            },
        },
        name: 'imageRelativeUrl',
    };
};

const appUrlValidationRule = i18n => {
    return {
        validation: {
            validate: value => APP_LINK_REGEXP.test(value),
            message: () => {
                return i18n.t('__validations.Please provide a valid app url');
            },
        },
        name: 'appUrl',
    };
};

export const nameCharactersRule = i18n => {
    return {
        validation: {
            validate: value =>
                /^(?!.*シ)(?=(.*\p{L}){2})[\p{L}\p{M}\p{Zs}'-]+$/u.test(value),
            message: () => {
                return i18n.t('__validations.nameCharacters');
            },
        },
        name: 'nameCharacters',
    };
};

export const vimeoUrlRule = i18n => {
    return {
        validation: {
            validate: value =>
                value.startsWith('https://') && REGEXP_VIMEO.test(value),
            message: () => {
                return i18n.t('__validations.Please provide a valid Vimeo url');
            },
        },
        name: 'vimeoUrl',
    };
};

const getCustomRules = (
    localeISOCode,
    $services,
    $errorHandler,
    i18n,
    $getLocaleConfigByKey
) => [
    complexPassword(),
    ...getPhoneNumberValidationRules($services, $errorHandler, i18n),
    ibanValidationRule(i18n),
    colorHexValidationRule(i18n),
    imageRelativeUrlRule(i18n),
    appUrlValidationRule(i18n),
    nameCharactersRule(i18n),
    vimeoUrlRule(i18n),
    postcodeValidationRule(
        $getLocaleConfigByKey(POSTCODE_VALIDATION_RULE_NAME)
    ),
    nipValidationRule($getLocaleConfigByKey(NIP_VALIDATION_RULE_NAME)),
    nipControlSumValidationRule(localeISOCode),
    blikPaymentCodeRule(),
    giftCardPaymentCodeRule(),
    giftCardPaymentPinRule(),
    emailCharactersRule(),
    ...getRmaValidations(i18n),
];

const importedRules = (
    localeISOCode,
    $services,
    $errorHandler,
    i18n,
    $getLocaleConfigByKey
) => [
    { name: REQUIRED_RULE_TYPE, validation: required },
    { name: EMAIL_RULE_TYPE, validation: email },
    { name: CONFIRMED_RULE_TYPE, validation: confirmed },
    { name: MIN_TYPE, validation: min },
    { name: MAX_TYPE, validation: max },
    { name: NUMERIC_TYPE, validation: numeric },
    ...getCustomRules(
        localeISOCode,
        $services,
        $errorHandler,
        i18n,
        $getLocaleConfigByKey
    ),
];

export function registerValidations(
    i18n,
    $services,
    $errorHandler,
    $getLocaleConfigByKey
) {
    importedRules(
        getLocaleISOCode(i18n.locales, i18n.locale),
        $services,
        $errorHandler,
        i18n,
        $getLocaleConfigByKey
    ).forEach(ruleConfig => {
        const { name, validation } = ruleConfig;

        extend(name, validation);
    });

    configure({
        // this will be used to generate messages.
        defaultMessage: (_, values) => {
            // eslint-disable-next-line no-underscore-dangle
            return i18n.t(`__validations.${values._rule_}`, values);
        },
    });
}

export default function ({ app }) {
    registerValidations(
        app.i18n,
        app.$services,
        app.$errorHandler,
        app.$getLocaleConfigByKey
    );
}
