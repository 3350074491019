import {
    FOOTER_CMS_BLOCKS_IDS,
    COOKIES_CMS_BLOCKS_IDS,
    FOOTER_SIMPLE_CMS_BLOCKS_IDS,
} from '@configs/footer';
import { DEFAULT_LOCALE_CODE } from '@configs/locale';
import { SIDEBAR_MENU_BLOCKS_IDS } from '@configs/sidebar';
import { NEWSLETTER_CMS_BLOCKS_IDS } from '@configs/newsletter';
import { SESSION_KEY } from '@configs/storage';
import { CMS_GLOBAL_CONFIG_ID } from '@configs/global-config';
import { SHOPPING_CMS_BLOCKS_IDS } from '@configs/shopping-info';
import { QUESTION_MARK_TOOLTIP_CMS_BLOCKS_IDS } from '@configs/question-mark-tooltip';
import { RETURN_DELIVERY_TOOLTIP_CMS_BLOCKS_IDS } from '@configs/return-delivery-tooltips';
import { CMS_MARKETING_BAR_GLOBAL_ID } from '@configs/marketing-bar';
import {
    PSB_SYNERISE_CONFIG_GENERIC_ID,
    PSB_SYNERISE_CONFIG_ID,
} from '@configs/synerise';
import { CMS_BLOCK_ID as SMART_BANNER_CMS_BLOCK_ID } from '@configs/smart-banner';

import {
    CORE_ERROR_DOMAIN,
    CLIENT_IDENTITY_DOMAIN,
} from '@errors/feature-domain-names';

import {
    ERROR_ACTION_TAG_NAME,
    ERROR_STORE_MODULE_TAG_NAME,
} from '@types/Errors';

import { removeHostPrefix } from '@assets/removeHostPrefix';
import { isRouteUsingFetchInParallel } from '@assets/fetch-in-parallel';
import { getSessionIdWithUUID } from '@assets/session';
import { saveCountryCodeForGTM } from '@assets/gtm-native-enhanced-conversion';
import { setGiftCardPaymentStore } from '@assets/payments';
import { isRecommendationsGeneric } from '@assets/recommendations';

import {
    types,
    NAVIGATION,
    CMS_BLOCKS,
    ESIZEME,
    GLOBAL_CONFIG,
    MARKETING_BAR_CONFIG,
} from './mutations';

const MODULE_NAME = 'root';

const cmsBlocksToLoad = [
    ...FOOTER_CMS_BLOCKS_IDS,
    ...COOKIES_CMS_BLOCKS_IDS,
    ...NEWSLETTER_CMS_BLOCKS_IDS,
    ...QUESTION_MARK_TOOLTIP_CMS_BLOCKS_IDS,
    ...RETURN_DELIVERY_TOOLTIP_CMS_BLOCKS_IDS,
];

const getPageBuilderBlocksToLoad = isRecommendationGeneric => {
    const pageBuilderBlocksToLoad = new Set([
        CMS_GLOBAL_CONFIG_ID,
        CMS_MARKETING_BAR_GLOBAL_ID,
        SMART_BANNER_CMS_BLOCK_ID,
        PSB_SYNERISE_CONFIG_ID,
    ]);

    pageBuilderBlocksToLoad.add(
        isRecommendationGeneric
            ? PSB_SYNERISE_CONFIG_GENERIC_ID
            : PSB_SYNERISE_CONFIG_ID
    );

    return pageBuilderBlocksToLoad;
};

export default {
    async nuxtServerInit({ dispatch }, { app, route, req, $config }) {
        const { host } = req.headers;

        dispatch('config/setBaseUrl', `https://${host}`);

        const { storeViews } = $config;

        const visibleStoreViews = storeViews.filter(
            ({ visible }) =>
                typeof visible === 'undefined' || parseInt(visible, 10) === 1
        );

        dispatch('config/setStoreViews', visibleStoreViews);

        dispatch('i18n/setI18nLocale', app.i18n.locale);

        const hostWithoutPrefix = removeHostPrefix(host);

        let currentStoreView = storeViews.find(
            ({ base_url }) =>
                base_url && removeHostPrefix(base_url) === hostWithoutPrefix
        );

        if (!currentStoreView) {
            currentStoreView = storeViews.find(
                ({ code }) => code === DEFAULT_LOCALE_CODE
            );
        }

        await dispatch('config/setStoreView', currentStoreView);

        await dispatch('config/setLocaleConfig', {
            locale: currentStoreView.locale,
        });

        if (!isRouteUsingFetchInParallel(route)) {
            await dispatch('getStoreData');
        }
    },

    nuxtClientInit({ dispatch, rootGetters }) {
        const { $storage, $errorHandler } = this.app;

        setGiftCardPaymentStore(this, dispatch);
        getSessionIdWithUUID(this, SESSION_KEY);
        saveCountryCodeForGTM({
            countryCode: rootGetters['config/countryCode'],
            $errorHandler,
            $storage,
        });
        dispatch('addVisibilityChangeEventListenerForAuth');
        dispatch('esizeme/loadEsizemeGuestScans');
        dispatch('synerise/setSyneriseConfig');
    },

    async getStoreFullData({ state, commit, dispatch }) {
        const { defaultCategoryId } = state.navigation;

        const {
            categoryList,
            cmsBlocks,
            pageBuilderBlocks,
            storeConfig,
        } = await this.$services.global.getStoreData(
            cmsBlocksToLoad,
            Array.from(
                getPageBuilderBlocksToLoad(
                    isRecommendationsGeneric(this.app.$abTests)
                )
            ),
            defaultCategoryId
        );

        const cmsBlocksAreValid = Boolean(
            cmsBlocks &&
                Array.isArray(cmsBlocks.items) &&
                cmsBlocks.items.length
        );

        if (cmsBlocksAreValid) {
            dispatch('cmsBlock/setCmsBlocks', cmsBlocks.items);
        }

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: CMS_BLOCKS,
            isValid: cmsBlocksAreValid,
        });

        const pageBuilderBlocksAreValid = Boolean(
            Array.isArray(pageBuilderBlocks?.items) &&
                pageBuilderBlocks.items.length
        );

        if (pageBuilderBlocksAreValid) {
            dispatch('cmsBlock/setCmsBlocks', pageBuilderBlocks.items);
        }

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: GLOBAL_CONFIG,
            isValid: pageBuilderBlocksAreValid,
        });

        const categoriesAreValid = Boolean(
            Array.isArray(categoryList) && categoryList.length
        );

        if (categoriesAreValid) {
            const rootCategoriesWrapper = categoryList[0];

            dispatch(
                'navigation/setNavigationCategories',
                rootCategoriesWrapper
            );
        }

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: NAVIGATION,
            isValid: categoriesAreValid,
        });

        const esizemeStoreConfigIsValid = Boolean(
            storeConfig && Object.keys(storeConfig).length
        );

        if (esizemeStoreConfigIsValid) {
            const {
                esizeme_api_enabled: isEsizemeApiEnabled,
                esizeme_customer_account_enabled: isEsizemeCustomerAccountEnabled,
                esizeme_category_pages_enabled: isEsizemeCategoryPagesEnabled,
                esizeme_category_pages_range_below: esizemeCategoryPagesRangeBelow,
                esizeme_category_pages_range_above: esizemeCategoryPagesRangeAbove,
                esizeme_product_page_enabled: isEsizemeProductPageEnabled,
                esizeme_top_bar_enabled: isEsizemeTopBarEnabled,
            } = storeConfig;

            dispatch('esizeme/setEsizemeStoreConfig', {
                isEsizemeApiEnabled,
                isEsizemeCustomerAccountEnabled,
                isEsizemeCategoryPagesEnabled,
                esizemeCategoryPagesRangeBelow,
                esizemeCategoryPagesRangeAbove,
                isEsizemeProductPageEnabled,
                isEsizemeTopBarEnabled,
            });
        }

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: ESIZEME,
            isValid: esizemeStoreConfigIsValid,
        });

        const { stanley_prefix: stanleyPrefix = null } = storeConfig || {};

        if (stanleyPrefix) {
            commit(types.SET_STANLEY_PREFIX, stanleyPrefix);
        }

        if (pageBuilderBlocksAreValid) {
            await Promise.allSettled([
                dispatch('config/loadGlobalConfig'),
                dispatch('synerise/loadSyneriseConfig'),
            ]);
        }

        if (
            !cmsBlocksAreValid ||
            !pageBuilderBlocksAreValid ||
            !categoriesAreValid ||
            !esizemeStoreConfigIsValid
        ) {
            throw new Error(
                // eslint-disable-next-line max-len
                `Error occurred during fetching full store data. Data validation results - cmsBlocks: ${cmsBlocksAreValid}, pageBuilderBlocks: ${pageBuilderBlocksAreValid}, categoryList: ${categoriesAreValid}, esizemeStoreConfigIsValid: ${esizemeStoreConfigIsValid}`
            );
        }
    },

    async loadNavigation({ commit, dispatch }) {
        const isValid = await dispatch('navigation/getNavCategoriesData');

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: NAVIGATION,
            isValid,
        });
    },

    async loadInitialCmsBlocks({ commit, dispatch }) {
        const isValid = await dispatch('cmsBlock/getCmsBlocks', {
            cmsBlocksToLoad,
        });

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: CMS_BLOCKS,
            isValid,
        });
    },

    async loadEsizemeStoreConfig({ commit, dispatch }) {
        const isValid = await dispatch('esizeme/getStoreConfig');

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: ESIZEME,
            isValid,
        });
    },

    async loadMarketingBarConfig({ commit, dispatch }) {
        const marketingBarBlock = await dispatch(
            'cmsBlock/getPageBuilderBlocks',
            {
                pageBuilderBlocksToLoad: [CMS_MARKETING_BAR_GLOBAL_ID],
            }
        );

        const isValid = Object.keys(marketingBarBlock).length > 0;

        commit(types.SET_DATA_LOADED, {
            dataLoadedName: MARKETING_BAR_CONFIG,
            isValid,
        });
    },

    async getStoreData({ state, dispatch, rootGetters }) {
        const { dataLoaded } = state;
        const { isConfigValid } = rootGetters;

        if (!isConfigValid) {
            const {
                [NAVIGATION]: navigationLoadedFlag,
                [CMS_BLOCKS]: cmsLoadedFlag,
                [ESIZEME]: esizemeStoreConfigFlag,
                [MARKETING_BAR_CONFIG]: marketingBarConfigFlag,
            } = dataLoaded;

            try {
                const dataLoadHandlers = [
                    {
                        loadActionName: 'loadNavigation',
                        shouldLoad: !navigationLoadedFlag,
                    },
                    {
                        loadActionName: 'loadInitialCmsBlocks',
                        shouldLoad: !cmsLoadedFlag,
                    },
                    {
                        loadActionName: 'loadEsizemeStoreConfig',
                        shouldLoad: !esizemeStoreConfigFlag,
                    },
                    {
                        loadActionName: 'loadMarketingBarConfig',
                        shouldLoad: !marketingBarConfigFlag,
                    },
                ].filter(loadDataHandler => loadDataHandler.shouldLoad);

                if (
                    dataLoadHandlers.length === Object.keys(dataLoaded).length
                ) {
                    await dispatch('getStoreFullData');
                } else {
                    await Promise.all(
                        dataLoadHandlers.map(handler =>
                            dispatch(handler.loadActionName)
                        )
                    );
                }
            } catch (err) {
                this.app.$errorHandler.captureDomainError(
                    CORE_ERROR_DOMAIN,
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'getStoreData',
                        [ERROR_STORE_MODULE_TAG_NAME]: MODULE_NAME,
                    }
                );
            }
        }
    },

    async getSidebarStoreData({ dispatch }) {
        await dispatch('cmsBlock/getCmsBlocks', {
            cmsBlocksToLoad: SIDEBAR_MENU_BLOCKS_IDS,
        });
    },

    async getFooterSimpleStoreData({ dispatch }) {
        await dispatch('cmsBlock/getCmsBlocks', {
            cmsBlocksToLoad: FOOTER_SIMPLE_CMS_BLOCKS_IDS,
        });
    },

    async getShoppingInfoStoreData({ dispatch }) {
        await dispatch('cmsBlock/getCmsBlocks', {
            cmsBlocksToLoad: SHOPPING_CMS_BLOCKS_IDS,
        });
    },

    setIsMobile({ state, commit }, isMobile) {
        if (state.isMobile !== isMobile) {
            commit(types.SET_IS_MOBILE, isMobile);
        }

        if (!state.isMobileEvaluated) {
            commit(types.SET_IS_MOBILE_EVALUATED, true);
        }
    },

    setIsChatWidgetLoading({ commit }, isChatWidgetLoading) {
        commit(types.SET_IS_CHAT_WIDGET_LOADING, isChatWidgetLoading);
    },

    setIsChatWidgetLoaded({ commit }, isChatWidgetLoaded) {
        commit(types.SET_IS_CHAT_WIDGET_LOADED, isChatWidgetLoaded);
    },

    setIsTabletOrDesktop({ state, commit }, isTabletOrDesktop) {
        if (state.isTabletOrDesktop !== isTabletOrDesktop) {
            commit(types.SET_IS_TABLET_OR_DESKTOP, isTabletOrDesktop);
        }

        if (!state.isTabletOrDesktopEvaluated) {
            commit(types.SET_IS_TABLET_OR_DESKTOP_EVALUATED, true);
        }
    },

    setAsyncModuleRegistered({ commit }, moduleName) {
        commit(types.SET_ASYNC_MODULE_REGISTERED, moduleName);
    },

    addPerformanceEntry({ commit }, entry) {
        commit(types.ADD_PERFORMANCE_ENTRY, entry);
    },

    setRouteRenderStart({ commit }, entry) {
        commit(types.SET_ROUTE_RENDER_START, entry);
    },

    addVisibilityChangeEventListenerForAuth({ state, dispatch }) {
        try {
            document.addEventListener('visibilitychange', async () => {
                if (document.visibilityState !== 'visible') {
                    return;
                }

                const oldAccessToken = state.customer.accessToken;

                await dispatch('customer/handleAuthTokens');

                const newAccessToken = state.customer.accessToken;

                const isDifferentAccessToken =
                    oldAccessToken !== newAccessToken;

                if (
                    newAccessToken &&
                    (!oldAccessToken || isDifferentAccessToken)
                ) {
                    window.location.reload();

                    return;
                }

                if (oldAccessToken && !newAccessToken) {
                    await dispatch('customer/handleExpiredSession', false);
                }
            });
        } catch (err) {
            this.app.$errorHandler.captureDomainError(
                CLIENT_IDENTITY_DOMAIN,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]:
                        'addVisibilityChangeEventListenerForAuth',
                    [ERROR_STORE_MODULE_TAG_NAME]: MODULE_NAME,
                }
            );
        }
    },
};
