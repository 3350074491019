import { createNamespacedHelpers } from 'vuex';

import { CLIENT_IDENTITY_DOMAIN } from '@errors/feature-domain-names';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { META_ROBOTS_NOINDEX_NOFOLLOW } from '@configs/seo';
import {
    ORIGINAL_REFERRER_KEY,
    GTM_CATEGORIES_STORAGE_KEY,
} from '@configs/storage';

import { METRICS_BY_PAGES } from '@configs/page-performance';

import { QUERY_ORDER, QUERY_ORDER_DIR } from '@configs/query-keys/sort';
import { QUERY_CURRENT_PAGE } from '@configs/query-keys/pagination';

import {
    CATALOG_PAGE_NAME,
    SEARCH_RESULTS_PAGE_NAME,
} from '@search/routing/names';

import GTMExcluded from '@models/GTMExcluded/GTMExcluded';

import { CHECKOUT_SUCCESS_PAGE_NAME } from '@router/names';

import {
    hasLoyaltyClubStoreModule,
    registerLoyaltyStoreModule,
} from '@loyalty-club/helpers/store';
import { STORE_MODULE_NAME as LOYALTY_STORE_MODULE_NAME } from '@loyalty-club/store/meta';

import { CONFIG, PAGE, AB_TEST } from '@analytics-types/Events';

import { MODULE_NAME as CLIENT_MODULE } from '@analytics-module/modules/client/meta';
import { USER_TYPE_STATUS_COMPLETE } from '@analytics-module/modules/client/types/Events';
import { MODULE_NAME as LOYALTY_CLUB_MODULE } from '@analytics-module/modules/loyalty-club/meta';
import { LOYALTY_CLUB_STATUS_COMPLETE } from '@analytics-module/modules/loyalty-club/types/Events';

import Deferred from '@core-assets/deferred';
import { getDeviceType } from '@assets/device';
import { startPerformanceMeasurement } from '@assets/performance';
import { isLoyaltyClubEnabled } from '@loyalty-club/assets';
import loadScript from '@assets/loadScript';
import { RECO_TEST_NAME } from '@assets/recommendations';

import { insiderScripts } from '@modules/insider/assets/insider';

import WithCanonicalUrl from '@mixins/WithCanonicalUrl';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const {
    mapState: mapCustomerState,
    mapGetters: mapCustomerGetters,
} = createNamespacedHelpers('customer');
const { mapActions: mapAvatarActions } = createNamespacedHelpers('avatar');
const {
    mapState: mapSyneriseState,
    mapActions: mapSyneriseActions,
} = createNamespacedHelpers('synerise');

export default ({ sendPageEvent = true } = {}) => ({
    data() {
        return {
            meta: {
                title: '',
                description: '',
                robots: META_ROBOTS_NOINDEX_NOFOLLOW,
            },
            responseStatusCode: 200,
            isPageEventEmittedDeferred: new Deferred(),
            isCustomerFetchedDeferred: new Deferred(),
            isPageEventSent: false,
        };
    },

    mixins: [WithCanonicalUrl],

    beforeCreate() {
        if (process.client) {
            const GTMExcludedProvider = new GTMExcluded(this.$storage);

            this.excludedCategories = GTMExcludedProvider.getCategoriesAnalyticsData();
            this.excludedProviders = GTMExcludedProvider.getProvidersAnalyticsData();
            this.areConsentsAccepted = GTMExcludedProvider.setAreConsentsAccepted();
        }
    },

    created() {
        if (process.client) {
            this.$analytics.waitPromise = this.isPageEventEmittedDeferred.promise;
        }
    },

    computed: {
        ...mapConfigGetters(['locale', 'currency', 'timezone', 'storeCode']),
        ...mapCustomerGetters([
            'customerUuid',
            'isLoggedIn',
            'customerHashedEmail',
            'userType',
        ]),
        ...mapCustomerState(['fetchingCustomerInProgress']),
        ...mapSyneriseState(['syneriseConfig', 'syneriseLoaded']),

        isLoyaltyClubEnabled() {
            return isLoyaltyClubEnabled(
                this.$abTests,
                this.storeCode,
                this.$cookies
            );
        },

        shouldSendPageEvent() {
            return sendPageEvent && !this.isPageEventSent;
        },
    },

    beforeRouteLeave(to, from, next) {
        const metricName = METRICS_BY_PAGES?.[to.name];

        if (metricName) {
            startPerformanceMeasurement(metricName);
        }

        this.$correlation.refreshIds();

        next();
    },

    watch: {
        fetchingCustomerInProgress: {
            async handler(fetchingInProgress) {
                if (fetchingInProgress) {
                    return;
                }

                this.isCustomerFetchedDeferred.resolve();
            },

            immediate: true,
        },

        $route: {
            handler(newRoute, oldRoute) {
                if (
                    ![CATALOG_PAGE_NAME, SEARCH_RESULTS_PAGE_NAME].includes(
                        newRoute.name
                    )
                ) {
                    return;
                }

                const QUERY_VIEW_CHANGED_PARAMS = [
                    QUERY_ORDER,
                    QUERY_ORDER_DIR,
                    QUERY_CURRENT_PAGE,
                ];

                const isPathChanged = newRoute.path !== oldRoute?.path;
                const isQueryChanged = QUERY_VIEW_CHANGED_PARAMS.some(
                    param => newRoute.query[param] !== oldRoute?.query?.[param]
                );

                if (isPathChanged || isQueryChanged) {
                    this.sendSynerisePageVisit();
                }
            },
        },
    },

    async beforeMount() {
        // Change title ASAP for Synerise analytics reading title tag
        document.title = this.$metaInfo?.title || document.title;

        if (this.shouldSendPageEvent) {
            await this.emitPageEvent();
        }
    },

    mounted() {
        this.loadSynerise();
        this.sendRecommendationTestAnalytics();

        if (this.$route.name !== CHECKOUT_SUCCESS_PAGE_NAME) {
            insiderScripts();
        }

        this.handleCreateAndConnectAvatarId();
    },

    methods: {
        ...mapAvatarActions(['connectClient']),
        ...mapSyneriseActions(['setSyneriseLoaded']),

        async loadSynerise() {
            const { syneriseConfig, syneriseLoaded } = this;

            const { apiKey, trackerKey, trackerDomain, scriptUrl } =
                syneriseConfig || {};

            if (!scriptUrl || !apiKey || !trackerKey || !trackerDomain) {
                return;
            }

            if (syneriseLoaded) {
                this.sendSynerisePageVisit();

                return;
            }

            await loadScript(syneriseConfig.scriptUrl);

            const consents = (
                this.$storage.getItem(GTM_CATEGORIES_STORAGE_KEY) || []
            ).reduce((acc, item) => {
                acc[item.code] = item.isActive;

                return acc;
            }, {});

            if (!window.SR || !window.SyneriseTC) {
                return;
            }

            const booleanToString = value => (value ? 'True' : 'False');

            await window.SR.init({
                trackerKey,
                customPageVisit: true,
                dynamicContent: {
                    virtualPage: 0,
                },
                dataLayer: window.dataLayer || [],
                disableNotification: true,
                disableFormMarker: true,
                disableDynamicContent: true,
                trackingDomain: trackerDomain,
            });

            window.SyneriseTC.sendFormData?.('consents', {
                Agreement_service_configuration: booleanToString(
                    consents.category_service_configuration
                ),
                Agreement_processes: booleanToString(
                    consents.category_processes
                ),
                Agreement_advertisement: booleanToString(
                    consents.category_advertisement
                ),
                Agreement_location: booleanToString(consents.category_location),
                Agreement_analysis_and_research: booleanToString(
                    consents.category_analysis_and_research
                ),
            });

            const syneriseLoadedEvent = new CustomEvent('SyneriseLoaded', {
                bubbles: true,
            });

            window.dispatchEvent(syneriseLoadedEvent);

            this.sendSynerisePageVisit();

            this.setSyneriseLoaded(true);
        },

        async sendSynerisePageVisit() {
            await this.isPageEventEmittedDeferred.promise;

            window.SR.event?.pageVisit?.();
        },

        async handleCreateAndConnectAvatarId() {
            try {
                if (!this.$avatar.avatarId) {
                    this.$avatar.createAvatarId();
                }

                if (!this.$avatar.isNewAvatarId) {
                    return;
                }

                await this.isCustomerFetchedDeferred.promise;

                if (!this.isLoggedIn) {
                    return;
                }

                await this.connectClient(this.$avatar.avatarId);
            } catch (err) {
                this.$errorHandler.captureDomainError(
                    CLIENT_IDENTITY_DOMAIN,
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]:
                            'handleCreateAndConnectAvatarId',
                    }
                );
            }
        },

        async sendGAEventWithUserType() {
            await this.isCustomerFetchedDeferred.promise;

            this.$analytics.moduleEmit(
                CLIENT_MODULE,
                USER_TYPE_STATUS_COMPLETE,
                {
                    eventLabel: this.userType,
                }
            );
        },

        async sendGAEventWithLoyaltyClubStatus() {
            if (!this.isLoyaltyClubEnabled) {
                return;
            }

            let loyaltyClubStatus = null;
            let loyaltyClubPoints = null;

            await this.isCustomerFetchedDeferred.promise;

            if (this.isLoggedIn) {
                if (!hasLoyaltyClubStoreModule(this.$store)) {
                    await registerLoyaltyStoreModule(this.$store);
                }

                const { isInitStatusLoaded } = this.$store.state[
                    LOYALTY_STORE_MODULE_NAME
                ];
                const isMember = this.$store.getters[
                    `${LOYALTY_STORE_MODULE_NAME}/isLoyaltyMember`
                ];

                if (!isInitStatusLoaded || (isInitStatusLoaded && isMember)) {
                    const data = await this.$store.dispatch(
                        `${LOYALTY_STORE_MODULE_NAME}/getLoyaltyMemberData`
                    );

                    const { status = null, activePoints = null } = data || {};

                    loyaltyClubStatus = status;
                    loyaltyClubPoints = activePoints;
                }
            }

            this.$analytics.moduleEmit(
                LOYALTY_CLUB_MODULE,
                LOYALTY_CLUB_STATUS_COMPLETE,
                {
                    loyaltyClubStatus,
                    loyaltyClubPoints,
                }
            );
        },

        async emitPageEvent({
            route,
            searchData,
            syneriseCampaignIds,
            ignoreReferrer,
        } = {}) {
            const originalReferrer = this.getOriginalReferrer();

            await this.$analytics.emit(
                CONFIG,
                {
                    pageLocation: document.location.href,
                    pageTitle: this.$metaInfo?.title || document.title,
                    pageReferrer: originalReferrer || this.$getPageReferrer(),
                    ignoreReferrer,
                },
                true
            );

            await this.$analytics.emit(
                PAGE,
                {
                    route: route || this.$route,
                    locale: this.locale,
                    currency: this.currency,
                    searchData,
                    deviceType: getDeviceType(),
                    referrer: originalReferrer,
                    syneriseContainerIds: syneriseCampaignIds,
                },
                true
            );

            if (originalReferrer) {
                this.removeOriginalReferrer();
            }

            this.isPageEventEmittedDeferred.resolve();

            this.isPageEventSent = true;

            if (this.$lux) {
                const { name = '' } = route || this.$route;

                if (name) {
                    this.$lux.label(name);
                }

                this.$lux.markLoadTime();
                this.$lux.readyToSend();
            }

            this.sendGAEventWithUserType();

            if (this.isLoyaltyClubEnabled) {
                this.sendGAEventWithLoyaltyClubStatus();
            }
        },

        getOriginalReferrer() {
            return this.$storage.getItem(ORIGINAL_REFERRER_KEY) || '';
        },

        removeOriginalReferrer() {
            this.$storage.removeItem(ORIGINAL_REFERRER_KEY);
        },

        async sendRecommendationTestAnalytics() {
            await this.isPageEventEmittedDeferred.promise;

            const recommendationTestVariant = this.$abTests.getVariant(
                RECO_TEST_NAME
            );

            if (recommendationTestVariant) {
                this.$analytics.emit(AB_TEST, {
                    testName: RECO_TEST_NAME,
                    variantName: recommendationTestVariant,
                });
            }
        },
    },
});
